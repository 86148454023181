@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap");

#who {
  padding: 80px 20% 80px 20%;
  font-size: clamp(18px, 5vw, 22px);
  background-color: #f2ece8;
  color: #3b6661;
  height: 100%;
}

h2 {
  font-family: "Shadows Into Light Two", "Segoe UI", Tahoma, Verdana, sans-serif;
  margin: 0px 0px 40px 0px;
  font-size: clamp(40px, 7vw, 50px);
  font-weight: 400;
  text-align: center;
}

.who-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.who-media {
  margin-right: 30px;
}

.who-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.justine-profil {
  height: 250px;
  width: auto;
  border-radius: 50%;
  margin: 20px 0px 20px 0px;
}

.button {
  align-self: center;
  background-color: #3b6661;
  color: #f2ece8;
  border-radius: 5px;
  border: none;
  width: 120%;
  height: 50px;
  font-size: 16px;
  margin: 15px 0px 20px 0px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

span {
  font-weight: 400;
}

@media screen and (max-width: 810px) {
  .who-content {
    flex-direction: column;
  }
  .who-media {
    margin-right: 0px;
  }
}
