#portfolio {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px 5% 80px 5%;
  font-size: clamp(18px, 5vw, 22px);
  background-color: #f2ece8;
  color: #3b6661;
  height: 100%;
}

.portfolio-text {
  margin: 20px 0px 10px 0px;
  font-family: "Dosis", "Roboto", sans-serif;
  font-size: clamp(16px, 5vw, 18px);
  font-weight: 200;
}

.portfolio-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.wrapper,
.single-card {
  height: 100%;
  max-width: 300px;
  margin: relative;
}

.wrapper {
  perspective: 900px;
  margin: 5%;
}

.single-card {
  text-align: center;
  transition: all 1.5s cubic-bezier(0.7, -0.5, 0.3, 1.8);
  transform-style: preserve-3d;
}

.wrapper:hover .single-card {
  transform: rotateY(180deg);
}

.front,
.back {
  width: 300px;
  position: relative;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}

.front {
  cursor: pointer;
  height: 100%;
  backface-visibility: hidden;
  background-size: contain;
  background-position: center center;
}

.back {
  transform: rotateY(180deg);
  position: absolute;
  height: 100%;
  background-color: #3b6661;
}

.content {
  padding: 20%;
}

.content h4 {
  margin: 0;
  font-weight: 500;
  color: #f2ece8;
}

h4 {
  font-size: 0.8em;
}

.portfolio-text-cards {
  font-size: 0.6em;
}

.portfolio-links {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #f2ece8;
}
