#footer {
  background-color: #b5a396;
  color: #f2ece8;
}

.style-footer {
  margin: 0px;
  padding: 20px 15% 20px 15%;
}

.links-footer {
  font-size: 22px;
  margin: 10px;
}

.white-links {
  text-decoration: none;
  color: #f2ece8;
  font-weight: 300;
}

.signature {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin: 10px;
  font-weight: 300;
}

.passion-fruit {
  width: 30px;
  height: auto;
  margin: 10px;
}

.legal {
  margin-top: -20px;
  font-size: 12px;
  font-weight: 300;
}

.white {
  color: #f2ece8;
  text-decoration: none;
}
