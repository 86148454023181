@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@200;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap");

#home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.background {
  object-fit: cover;
  width: auto;
  height: 100vh;
}

.logo {
  top: 5px;
  left: 5px;
  width: 130px;
  height: auto;
  position: fixed;
}

.text {
  align-self: center;
  flex-wrap: wrap;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.absolute {
  position: absolute;
}

.name {
  font-size: clamp(20px, 4em, 90px);
  font-weight: 300;
  color: #3b6661;
  margin-bottom: -25px;
}

h1 {
  display: flex;
  justify-content: center;
  font-family: "Shadows Into Light Two", "Segoe UI", Tahoma, Verdana, sans-serif;
  font-size: clamp(20px, 1.6em, 70px);
  font-weight: 200;
  text-align: center;
  display: contents;
  margin: -20px;
  flex-wrap: wrap;
}

.arrow {
  display: flex;
  fill: #f2ece8;
  width: 40px;
  height: 40px;
  align-self: center;
  bottom: 3%;
  position: absolute;
  animation: bounce 0.35s ease infinite alternate;
  cursor: pointer;

  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }
}

@media screen and (max-width: 480px) {
  .logo {
    visibility: hidden;
  }
}
