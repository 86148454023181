@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&family=Shadows+Into+Light+Two&display=swap");

body {
  width: 100%;
  margin: 0;
  font-family: "Montserrat", "Roboto", sans-serif;
  color: #f2ece8;
  font-weight: 200;
  margin: 0px;
  padding: 0px;
  animation: overflow-body 3s;
  height: 100vh;

  @keyframes overflow-body {
    0% {
      overflow: hidden;
    }
    99% {
      overflow: hidden;
    }
    100% {
      overflow: auto;
    }
  }
}

.App {
  text-align: center;
}

.section-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #f2ece8;
  animation: loading 1.5s;
  z-index: -1;

  @keyframes loading {
    0% {
      z-index: 9999;
      opacity: 1;
    }
    99% {
      z-index: 9999;
      opacity: 1;
    }
    100% {
      z-index: 0;
      opacity: 0;
    }
  }
}

.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #6fa9a29c;
  border-radius: 50%;
  border-top-color: #3b6661;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
