#skills {
  padding: 80px 5% 80px 5%;
  font-size: clamp(18px, 5vw, 22px);
  text-align: justify;
  background-color: #b5a396;
  color: #f2ece8;
  height: 100%;
}

.skills-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.skills-container {
  margin: 15px;
  width: 250px;
}

.skills-bar {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: #3b6661;
}

h3 {
  font-weight: 200;
}

h4 {
  color: #3b6661;
  margin: 0px;
  font-weight: 300;
}

.font-light {
  font-weight: 200;
}

.skills-progress-bar {
  display: flex;
  height: 1rem;
  background-color: #f2ece8;
  border-radius: 0.25rem;
}

.seventy {
  background-color: #3b6661;
  border-radius: 0.25rem;
  width: 70%;
}

.seventyfive {
  background-color: #3b6661;
  border-radius: 0.25rem;
  width: 75%;
}

.eighty {
  background-color: #3b6661;
  border-radius: 0.25rem;
  width: 80%;
}

.eightyfive {
  background-color: #3b6661;
  border-radius: 0.25rem;
  width: 85%;
}

.ninety {
  background-color: #3b6661;
  border-radius: 0.25rem;
  width: 90%;
}

.ninetyfive {
  background-color: #3b6661;
  border-radius: 0.25rem;
  width: 95%;
}

.ninetynine {
  background-color: #3b6661;
  border-radius: 0.25rem;
  width: 99%;
}

.hundred {
  background-color: #3b6661;
  border-radius: 0.25rem;
  width: 100%;
}
