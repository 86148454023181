label .menu {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 100;
  width: 100px;
  height: 100px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

label .hamburger {
  position: absolute;
  top: 45px;
  left: 40px;
  width: 35px;
  height: 3px;
  background: #f2ece8;
  transition: 0.5s ease-in-out;
}

label .hamburger:after,
label .hamburger:before {
  transition: 0.5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #f2ece8;
}

label .hamburger:before {
  top: -10px;
}

label .hamburger:after {
  bottom: -10px;
}

label input {
  display: none;
}

label input:checked + .menu {
  box-shadow: 0 0 0 100vw #3b6661;
  background-color: #3b6661;
  opacity: 0.9;
  border-radius: 0;
  height: 100vh;
}

label input:checked + .menu .hamburger {
  transform: rotate(45deg);
}

label input:checked + .menu .hamburger:after {
  transform: rotate(90deg);
  bottom: 0;
}

label input:checked + .menu .hamburger:before {
  transform: rotate(90deg);
  top: 0;
}

label input:checked + .menu + ul {
  opacity: 1;
}

label ul {
  z-index: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.25s 0s ease-in-out;
  list-style-type: none;
}

label a {
  margin-bottom: 1em;
  display: block;
  color: #f2ece8;
  text-decoration: none;
}

ul {
  align-content: center;
  padding: 0px;
}

.menu-text {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}

.link-menu {
  font-size: 20px;
  font-weight: 200;

  &:hover {
    opacity: 0.5;
  }
}

@media screen and (max-width: 480px) {
  .menu-text {
    font-size: 20px;
  }
  .link-menu {
    font-size: 14px;
  }
  label a {
    margin-bottom: 10px;
  }
}
