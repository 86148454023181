#contact {
  padding: 100px 20% 100px 20%;
  font-size: clamp(18px, 5vw, 22px);
  text-align: center;
  background-color: #f2ece8;
  color: #3b6661;
  height: 100%;
}

.contact-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icons {
  height: 30px;
  width: auto;
  margin: 20px;
}
