#green {
  padding: 80px 20% 80px 20%;
  font-size: clamp(18px, 5vw, 22px);
  text-align: justify;
  background: #3b6661;
  color: #f2ece8;
  height: 100%;
}

.green-eco-content {
  display: flex;
  justify-content: center;
}

.green-block {
  display: flex;
  flex-direction: column;
  margin: 40px;
  text-align: start;
  width: 50%;
}

.green-title {
  font-size: clamp(20px, 2em, 30px);
  color: #f2ece8;
  font-weight: 200;
}

.small-texte {
  font-weight: 200;
  font-size: clamp(11px, 5vw, 12px);
}

.center {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 810px) {
  .green-eco-content {
    flex-direction: column;
    align-items: center;
  }
  .green-block {
    width: 80%;
  }
}
